@import '../../../styles/mixins';

.release-card {
  row-gap: 10px;
  padding: 10px;

  @include breakpoint($bp-tablet) {
    row-gap: 20px;
    padding: 30px;
  }

  h2 {
    @include typo('title-h1');

    text-align: center;
  }

  p {
    @include typo('date');

    text-align: center;
  }
}
