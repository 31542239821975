@import '../../styles/mixins';

.iconic-link {
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
  height: 100%;

  @include breakpoint() {
    column-gap: 10px;
  }

  svg {
    width: 1.56em;
    height: 1.56em;
  }
}
