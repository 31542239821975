@import '../../../styles/mixins';

.article-full {
  width: 100%;
  padding: 10px;

  @include breakpoint {
    padding: 30px;
  }

  .article-inner-wrapper {
    margin-bottom: -16px;

    @include breakpoint {
      margin-bottom: -20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    img,
    > ul,
    > ol {
      margin-bottom: 16px;

      @include breakpoint {
        margin-bottom: 20px;
      }
    }

    ul,
    ol {
      padding-left: 1.3em;
    }

    li > ul,
    li > ol {
      margin-top: 8px;

      @include breakpoint {
        margin-top: 4px;
      }
    }

    ul {
      list-style: disc;
    }

    img {
      display: block;
      width: 100%;
    }

    h1 {
      @include typo('title-1');
    }

    h2 {
      @include typo('title-2');
    }

    h3 {
      @include typo('title-3');
    }

    h4 {
      @include typo('title-4');
    }
  }
}