@import '../../../styles/vars';
@import '../../../styles/mixins';

$menu-item-padding: 10;

.main-header {
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
  width: 100%;
  padding: 32px #{$padding-content-mobile}px 0 #{$padding-content-mobile}px;
  z-index: $z-header;
  
  @include mobile {
    background: no-repeat fixed top 0 center url('/img/bg-ornament.svg') $clr-violet;
    background-size: auto 130px;
  }

  @include breakpoint {
    row-gap: 40px;
    padding: 20px #{$padding-content-desktop}px 10px #{$padding-content-desktop}px;
    background-color: $clr-violet;
  }
}

.main-logo-wrapper {
  margin: auto;

  img {
    display: block;
    width: 151px;
    max-width: 100vw;
    height: 67px;
    object-fit: contain;

    @include breakpoint {
      width: 270px;
      height: 120px;
    }
  }
}

.main-navigation {
  max-width: 100vw;
  margin: 0 -#{$padding-content-mobile}px;
  overflow-x: auto;
  overflow-y: hidden;

  ul {
    padding: 0 #{$padding-content-mobile - $menu-item-padding}px;
    
    @include mobile {
      column-gap: 0;
    }

    @include breakpoint {
      padding: 0 #{$padding-content-desktop - $menu-item-padding}px;
    }

    li a,
    li.active {
      display: block;
      padding: 10px;
    }
  }
}
