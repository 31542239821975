@import '../../../styles/vars';
@import '../../../styles/mixins';

.page-content {
  max-width: #{$max-content}px;
  margin: 0 auto;
  width: 100%;

  @include breakpoint {
    padding: 0 #{$padding-content-desktop}px;
  }

  .inner-wrapper {
    flex-grow: 1;
    padding-top: 16px;
    padding-bottom: 32px;

    @include mobile {
      grid-template-rows: auto 1fr;
    }

    @include breakpoint {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  main {
    grid-template-rows: 1fr auto;
    height: 100%;

    @include mobile {
      padding: 0 #{$padding-content-mobile}px;
    }
  
    ul {
      margin-bottom: auto;
    }
  }

  aside {
    @include mobile {
      order: -1;
    }
  }

  .aside-content {
    width: fit-content;
  
    @include mobile {
      padding: 0 #{$padding-content-mobile}px;
    }
  }
}
