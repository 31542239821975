@import '../../../styles/mixins.scss';

.announce-card {
  row-gap: 10px;
  padding: 10px;
  text-align: center;

  @include breakpoint {
    row-gap: 20px;
    padding: 30px;
  }

  img {
    display: block;
    width: 143px;
    height: 143px;

    @include breakpoint {
      width: 305px;
      height: 305px;
    }
  }

  h3 {
    @include typo;
  }
}
