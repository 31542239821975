@import 'vars';

@mixin breakpoint($breakpoint: $bp-desktop) {
  @media (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$bp-desktop - 1}px) {
    @content;
  }
}

@mixin hover {
  @media (hover), (min--moz-device-pixel-ratio: 0) {
    @content;
  }
}

@mixin title($type: 'h1') {
  font-weight: 600;

  @if $type == 'h1' {
    @include typo('title-1');
  } @else if $type == 'h2' {
    @include typo('title-2');
  } @else if $type == 'h3' {
    @include typo('title-3');
  } @else if $type == 'h4' {
    @include typo('title-4');
  }
}

@mixin typo($type: 'normal') {
  @if $type == 'normal' {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;

    @include breakpoint() {
      font-size: 22px;
    }
  } @else if $type == 'menu' {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;

    @include breakpoint() {
      font-size: 20px;
      line-height: 1.15;
    }
  } @else if $type == 'link' {
    font-size: 16px;
    line-height: 1.1875;
    font-weight: 400;

    @include breakpoint() {
      font-size: 20px;
      line-height: 1.15;
    }
  } @else if $type == 'footer' {
    font-size: 14px;
    line-height: 1.14;
    font-weight: 400;

    @include breakpoint() {
      font-size: 18px;
      line-height: 1.17;
    }
  } @else if ($type == 'date') {
    font-size: 14px;
    line-height: 1.14;
    font-weight: 400;

    @include breakpoint() {
      font-size: 16px;
      line-height: 1.1875;
    }
  } @else if ($type == 'pagination') {
    font-size: 14px;
    line-height: 1.14;
    font-weight: 400;

    @include breakpoint() {
      font-size: 16px;
      line-height: 1.1875;
    }
  } @else if $type == 'title-1' {
    font-size: 28px;
    line-height: 1.18;
    font-weight: 600;

    @include breakpoint() {
      font-size: 32px;
      line-height: 1.1875;
    }
  } @else if $type == 'title-2' {
    font-size: 24px;
    line-height: 1.17;
    font-weight: 600;

    @include breakpoint() {
      font-size: 28px;
      line-height: 1.18;
    }
  } @else if $type == 'title-3' {
    font-size: 20px;
    line-height: 1.15;
    font-weight: 600;

    @include breakpoint() {
      font-size: 25px;
      line-height: 1.16;
    }
  } @else if $type == 'title-4' {
    font-size: 16px;
    line-height: 1.1875;
    font-weight: 600;

    @include breakpoint() {
      font-size: 22px;
      line-height: 1.18;
    }
  }
}

/*
@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
*/

@mixin ellipsis($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin responsive($property, $mobileValue, $desktopValue, $breakpoint: 768) {
  #{$property}: calc(100vw * $mobileValue / $template-mobile-size);

  @media (min-width: #{$breakpoint}px) {
    #{$property}: calc(min(100vw, #{$max-content}px) * $desktopValue / $template-desktop-size);
  }
}
