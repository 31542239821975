@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.pagination-item {
  display: inline-flex;
  padding: 10px 16px;

  @include breakpoint {
    padding: 10px 20px;
  }

  &.active {
    color: $clr-white;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &.dots {
    padding: 10px 0;
    cursor: default;
  }
}
