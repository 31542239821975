@import '../../../styles/mixins';

.pagination-wrapper {
  overflow-x: auto;
  
  @include mobile {
    width: 100vw;
    margin: 0 -#{$padding-content-mobile}px;
    padding: 0 #{$padding-content-mobile}px;
  }
}

.pagination-panel {
  @include typo('pagination');

  display: inline-grid;
  margin-right: auto;
  overflow-x: auto;
}
