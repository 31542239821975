@import '../../node_modules/modern-normalize/modern-normalize';

@import 'vars';
@import 'mixins';
@import 'font';

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
}

body {
  @include typo;

  display: flex;
  flex-direction: column;
  color: $clr-white;
  font-family: 'Roboto', sans-serif;
  background-color: $clr-violet;

  @include breakpoint {
    background: no-repeat fixed top 240px center url('/img/bg-ornament.svg') $clr-violet;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

h1 {
  @include typo('menu');
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a:any-link {
  color: inherit;
  text-decoration: none;
  transition: color $duration-default ease;
  outline: none;

  svg path {
    transition: fill $duration-default ease;
  }
}

.content-wrapper {
  max-width: #{$max-content}px;
  margin: 0 auto;
}

.page-content {
  position: relative;
  display: flex;
  flex-grow: 1;

  main {
    flex-grow: 1;
  }

  aside {
    width: 100vw;

    @include mobile {
      overflow-x: auto;
    }

    @include breakpoint {
      width: 365px;
      margin-left: 0;
    }
  }
}

.widget {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.hoverable {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: '';
    transition: background-color $duration-default ease;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    @include hover {
      &::after {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.link:hover {
  @include hover {
    color: $clr-gray-light;

    svg path {
      fill: $clr-gray-light;
    }
  }
}

.link:focus {
  color: $clr-orange;

  svg path {
    fill: $clr-orange;
  }
}

.link:active,
.active {
  color: $clr-muddy;

  svg path {
    fill: $clr-muddy;
  }
}

.grid-h,
.grid-v,
.grid-h-v,
.grid-v-h {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  
  @include breakpoint {
    column-gap: 20px;
    row-gap: 20px;
  }
}

.grid-h {
  grid-auto-flow: column;
}

.grid-v {
  grid-auto-flow: row;
}

.grid-h-v {
  grid-auto-flow: column;
  
  @include breakpoint {
    grid-auto-flow: row;
  }
}

.grid-v-h {
  grid-auto-flow: row;
  
  @include breakpoint {
    grid-auto-flow: column;
  }
}

.with-aside {
  @include breakpoint {
    grid-template-columns: 1fr auto;
  }
}

.menu {
  @include typo('menu');
}
