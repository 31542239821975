@import '../../../styles/vars';
@import '../../../styles/mixins';

.social-panel {
  padding: #{$padding-content-mobile - 8}px 0;
  font-size: 18px;

  @include mobile {
    max-width: 44px;
  }

  @include breakpoint {
    padding: 30px 0;
  }

  ul {
    align-items: center;
    justify-content: center;
    row-gap: 0;
    width: 100%;
    height: 100%;
    
    @include breakpoint {
      column-gap: 30px;
    }

    a {
      @include mobile {
        padding: 8px 10px;
      }
    }
  }
}
