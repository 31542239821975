@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
