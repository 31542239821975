@import '../../../styles/mixins';

.event-item {
  @include typo;

  row-gap: 10px;
  padding: 10px;

  @include breakpoint {
    grid-template-columns: auto 1fr auto;
    column-gap: 50px;
    padding: 30px 60px;
  }
}

.event-description {
  @include typo;
  
  font-weight: 400;
  
  @include breakpoint {
    @include ellipsis;
  }
}

a.event-link {
  @include typo('link');

  text-decoration: underline;

  @include breakpoint {
    padding-top: 4px;
  }
}
