@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.release-list {
  grid-template-columns: 1fr;

  @include breakpoint($bp-tablet) {
    grid-template-columns: 1fr 1fr;
  }
}
