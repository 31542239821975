@import '../../../styles/mixins';

.article-card {
  row-gap: 10px;
  width: 100%;
  padding: 10px;

  @include breakpoint {
    row-gap: 20px;
    padding: 30px;
  }

  .article-date {
    @include typo('date');
  }

  .article-link {
    @include typo('link');

    font-weight: 600;
    text-decoration: underline;
  }
}
