@import '../../../styles/vars';
@import '../../../styles/mixins';

.main-footer {
  @include typo('footer');

  padding: 6px 0;
  background-color: $clr-violet-dark;

  @include breakpoint {
    padding: 26px 0;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: #{$padding-content-mobile}px;
    padding-left: #{$padding-content-mobile}px;

    @include breakpoint {
      padding-right: #{$padding-content-desktop}px;
      padding-left: #{$padding-content-desktop}px;
    }
  }

  .socials {
    column-gap: 0;

    @include breakpoint() {
      column-gap: 20px;
    }

    a {
      padding: 8px;

      @include breakpoint() {
        padding: 0;
      }
    }
  }
}
